import journalApi from "@/api/journalApi"

export const loadEntries = async ({ commit }) => {
    const { data } = await journalApi.get('/entries.json')
    const entries = []
    if (data !== null ) {
        for (let id of Object.keys( data )) {
            entries.push({
                id,
                ...data[id]
            })
        }
    }

    commit('setEntries', entries)
}

export const updateEntry = async ({ commit }, entry) => {
    const {date, picture, text} = entry
    const dataToSave = { date, picture, text}
    const resp = await journalApi.put(`/entries/${ entry.id }.json`, dataToSave)

    dataToSave.id = entry.id

    commit('updateEntry', { ...dataToSave })
}

export const addEntry = async ({ commit }, entry) => {
    const { data } = await journalApi.post('/entries.json', entry)
    entry.id = data.name
    commit('addEntry', { ...entry })

    return entry.id
}

export const deleteEntry = async ({ commit }, id) => {
    const { data } = await journalApi.delete(`/entries/${id}.json`)
    commit('deleteEntry', id)
}

