<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">
    <h1>Hola mundo</h1>
    <button type="button" class="btn btn-primary">Button primary </button>
    <button type="button" class="btn btn-secondary">Button secondary</button>
    <button @click="goToDaybook" type="button" class="btn btn-success">Button success</button>
  </div>
</template>

<script>
export default {
  methods: {
    goToDaybook() {
      this.$router.push({ name: 'no-entry' })
    }
  }
}
</script>
